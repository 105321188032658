<template>
  <div class="d-flex content-center min-vh-100 noto-sans">
    <BContainer>
      <BRow class="justify-content-center">
        <BCol md="9">
          <BCard class="text-left mt-4 " footer-bg-variant="light" footer-tag="footer">

            <div v-show=" so.payStat==='99' " class="df-end">
              <img src="@/assets/svg/icon-cross.svg" alt="주문취소" class="icon-row-btn" @click="cancelButton" :disabled="isCardSubmiting">
            </div>

            <div ref="pdfSection">
              <div style="margin-bottom: 2rem;">
                <img src="../../../../public/images/HowCost-title-logo.png" alt="하우코스트" style="width: 120px;">
                <hr style="margin-top:0.325rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 2px;background-image: linear-gradient(to right, #63a3ff 10%, #8465fa 76%, #3178b8 95%);">
              </div>

              <div style="max-width: 92%; margin: 1.525rem auto">

                <div style="display: flex;justify-content: space-between;align-items: center;">
                  <div style="text-align: left;">
                    <h5>주문정보</h5>
                  </div>
                  <div style="text-align: right;">
                    주문번호: <strong style="color: #417fda;">{{ orderNumber }}</strong>
                  </div>
                </div>

                <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

                <BRow>
                  <BCol v-if="so!==null">

                    <!-- 주문정보 -->
                    <div style="margin: 0.525rem 0;">
                      <div style="margin: 0.725rem 0; display: inline-flex;align-items: center;">
                        <div class="mr-4" style="text-align: left;">
                          <img :src="$imgUrl + so.salesMaster.image" alt="상품이미지" style="width: 100px; height: 80px;border: solid 1px #f3f3f3;border-radius: 10px;">
                        </div>
                        <div style="text-align: right;">
                          <span style="font-size: 12.8pt;font-weight: 500;">{{ so.salesMaster.title }}</span>
                        </div>
                      </div>

                      <div style="margin: 0.525rem 0;">
                        <BTable hover
                                class=""
                                style="font-size: 9.6pt;"
                                :items="so.products"
                                :fields="prdFields"
                                ref="productTable"
                                id="productTable">

                          <template #cell(unit_price)="data">
                            <div v-if="data.item.discount_yn === 'Y'">
                              <span>{{ commify((data.item.unit_price - data.item.discount_price)) }}원</span>
                              <span class="ml-2" style="text-decoration-line: line-through; color: #bababa;font-size: 9.2pt;">{{ commify((data.item.unit_price)) }}</span>
                            </div>
                            <span v-else>{{ commify( data.item.unit_price ) }}원</span>
                          </template>
                        </BTable>
                      </div>
                    </div>

                    <!-- 주문자 정보 -->
                    <div style="margin: 2rem 0 1.225rem 0;">
                      <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div style="text-align: left;">
                          <h5 class="mb-0">주문자 정보</h5>
                        </div>
                      </div>

                      <hr class="mt-2" style="display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

                      <div style="font-size: 11pt;">
                        <table>
                          <tbody>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">주문계정</th>
                            <td>{{ so.userId }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">주문자명</th>
                            <td>{{ so.name }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">이메일</th>
                            <td>{{ so.email }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">연락처</th>
                            <td>{{ so.phoneNo }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <hr class="mt-4 mb-1" style="display: block;width: 100%;height: 1px;background-color: #e8e8e8;opacity: 0.5;">

                      <p class="mt-0" style="color: #808080; font-size: 9pt;">
                        * 주문자 정보가 제대로 표기되지 않을 경우 회원정보를
                        <a href="#/service/member-password-verify" target="_blank">업데이트</a>해주세요.
                      </p>
                    </div>

                    <!-- 사업자 -->
                    <div v-show="so.taxStat==='01'" style="margin: 3rem 0 1.525rem 0;">
                      <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div style="text-align: left;">
                          <h5 class="mb-0">사업자 등록정보</h5>
                        </div>
                        <div style="text-align: right;">
                          <p style="margin-bottom: -10px; font-size: 9pt; color: #8e8e8e;">계산서 발행을 위한 정보입니다.</p>
                        </div>
                      </div>

                      <hr class="mt-2" style="display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

                      <div style="font-size: 11pt;">
                        <table>
                          <tbody>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">회사명</th>
                            <td>{{ business.compName || so.compName }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">대표자명</th>
                            <td>{{ business.prName || so.prName }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">업태/업종</th>
                            <td>{{ business.bizKind || so.bizKind }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">사업자번호</th>
                            <td>{{ business.compNo || so.compNo }}</td>
                          </tr>
                          <tr>
                            <th style="width: 130px; font-weight: 400; color: #aaaaaa;">사업장주소</th>
                            <td>{{ business.address || so.address }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr class="mt-4 mb-1" style="display: block;width: 100%;height: 1px;background-color: #e8e8e8;opacity: 0.5;">
                    </div>


                    <!-- 결제금액 -->
                    <div v-if=" so.payStat==='99' || so.payStat==='00' " class="mb-2" style="display: flex; justify-content: end; font-size: 12pt;">
                      <div class="mt-2" style="width: 42%">
                        <div class="mb-1" style="display: flex; justify-content: space-between; font-size: 13.8pt;">
                          <div>
                            <strong>최종 결제금액</strong>
                            <small style="font-size: 66%"> (VAT포함)</small>
                          </div>
                          <strong style="color: #63a3ff;">{{ commify(totalPrice) }}원</strong>
                        </div>
                        <div>
                          <div class="ml-1" style="border-left: 3px solid #e1e1e1; color: #989898;">
                            <div class="pl-2" style="display: flex; justify-content: space-between;">
                              <span>제품금액</span>
                              <span>{{ commify(unitPrice) }}원</span>
                            </div>
                            <div v-if="discountPrice > 0" class="pl-2" style="display: flex; justify-content: space-between;">
                              <span>할인금액</span>
                              <span>-{{ commify(discountPrice) }}원</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <!-- payment 처리하는 곳인데 사용하지 않는듯 (데이터가 없음) -->
                    <div v-if="payment!==null" id="payInfo">
                      <BCard border-variant="primary"
                             class="m-0"
                             header="primary"
                             header-bg-variant="primary"
                             header-text-variant="white">
                        <div slot="header">
                          <strong>결제정보</strong>
                          <div class="card-header-actions">
                            <small>주문제품의 결제 정보입니다</small>
                          </div>
                        </div>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-2"/>
                            결 제 일
                          </BInputGroupPrepend>
                          <BFormInput :value="so.pay_dt" class="bg-white" readonly type="text"/>
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            결제방식
                          </BInputGroupPrepend>
                          <b-form-input :value="payMethods[payment.PayMethod]" class="bg-white" readonly type="text" />
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            결제금액
                          </BInputGroupPrepend>
                          <BFormInput :value="payment.Amt" class="bg-white" readonly type="text"/>
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            카드사명
                          </BInputGroupPrepend>
                          <BFormInput :value="payment.fn_name" class="bg-white" readonly type="text"/>
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            카드번호
                          </BInputGroupPrepend>
                          <BFormInput :value="payment.CardNum" class="bg-white" readonly type="text"/>
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            할부개월
                          </BInputGroupPrepend>
                          <BFormInput :value="payment.CardQuota" class="bg-white" readonly type="text"/>
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            승인번호
                          </BInputGroupPrepend>
                          <BFormInput :value="payment.AuthCode" class="bg-white" readonly type="text"/>
                        </BInputGroup>

                        <BInputGroup class="mb-1">
                          <BInputGroupPrepend is-text>
                            <BIconCreditCard2Front class="mr-1"/>
                            결제결과
                          </BInputGroupPrepend>
                          <BFormInput :value="payment.ResultMsg" class="bg-white" readonly type="text"/>
                        </BInputGroup>
                      </BCard>
                    </div>

                  </BCol>
                </BRow>
              </div>
            </div>

            <BRow v-if=" so.payStat==='99' " class="py-3 m-0 mt-4 mb-3" style="border-top: solid 1px rgba(175, 175, 175, 0.641); border-bottom: solid 1px rgba(175, 175, 175, 0.641);">
              <BCol align-self="end" class="text-right">
                <BFormCheckbox class="mt-1 display-in" id="agreeTerms" v-model="so.agreement" name="agreeTerms" value="Y" unchecked-value="N">
                  <span class="text-center noto-sans" style="font-size: 11pt; font-weight: 500;">구매 약관 동의 (필수)</span>
                </BFormCheckbox>
                <b-icon class="ml-1 c-pointer text-right" icon="caret-right-fill" v-b-modal.termsModal />
              </BCol>
            </BRow>

            <BRow v-if=" so.payStat==='00' " class="mt-1">
              <BCol class="text-center">
                <BButton class="mt-2 mb-2" variant="info" @click="gotoXcostMe">홈페이지로 이동</BButton>
              </BCol>
            </BRow>

            <BRow v-if="so!==null">
              <BCol>
                <li v-show="so.payStat==='00'" class="list-group-item bg-dark text-white mt-2 mt-0">
                  <strong>결제 취소 및 구매 철회 안내</strong><br/>
                  <span class="text-muted">
                      결제 후 15일 이내 구매를 취소 할 수 있으며, 제품키 및 사용권한 등은 <u>취소시 즉시 삭제</u>됩니다.<br/>
                      <b>단, 제품키를 인증하였거나 유료서비스를 이용한 경우에는 구매 철회를 할 수 없습니다.</b><br/>
                      구매 철회시 <B><u>주문번호 및 취소사유를 기재</u></B>하여 <a href="http://xcost.me/bbs/qalist.php">고객센터</a>나 이메일(<b>estimate@howcost.co.kr</b>)로 보내주시기 바랍니다..<br/>
                      담당자 확인 후 취소까지 최대 14일이 소요될 수 있습니다.
                  </span>
                </li>
              </BCol>
            </BRow>


            <div v-if="so!==null">
              <div v-show="so.payStat!=='00'" class="mt-2">
<!--                <div style="width: 50%; position: absolute;">
                  <div class="df-start">
                    <p>
                      사업자 정보가 보이지 않으세요?
                      사업자 정보를 <a href="#/service/member-password-verify" target="_blank">등록</a>해주세요!
                    </p>
                  </div>
                </div>-->
                <div>
                  <div class="df-end mb-2">
                    <button class="btn-howcost" style="height: 50px; width: 40.9%" @click="payWithCreditCard" :disabled="isSubmiting||isCardSubmiting">주문 결제하기</button>
                  </div>
                  <div class="df-end">
                    <button class="btn-howcost mr-2" style="width: 28%; background-color: #5cb059" @click="payWithCash" :disabled="isSubmiting||isCardSubmiting">계좌이체</button>
                    <button class="btn-howcost" style="width: 12%; background-color: #a3a3a3" @click="saveAsPdf">견적서 출력</button>
                  </div>
                </div>
              </div>
<!--              <BRow v-show="so.payStat!=='00'" class="mt-2">
                <BCol class="text-right">

&lt;!&ndash;                  <BButton v-show="isBoucherOrder"
                           @click="payWithBoucher"
                           variant="primary">
                    <BIconCash/> 바우처 신청 확인
                  </BButton>&ndash;&gt;

                  <BButton type="button" variant="primary"
                           v-show="!isBoucherOrder"
                           :disabled="isSubmiting||isCardSubmiting"
                           @click="payWithCash">
                    <BSpinner v-show="isSubmiting" class="mr-1" small></BSpinner>
                    <BIconCash/>
                    <strong> 현금결제</strong>
                  </BButton>

                  <BButton type="button" variant="warning"
                           v-show="!isBoucherOrder"
                          :disabled="isSubmiting||isCardSubmiting"
                           class="ml-2"
                           @click="payWithCreditCard">
                    <BSpinner v-show="isCardSubmiting" class="mr-1" small></BSpinner>
                    <BIconCreditCardFill/>
                    <strong> 카드결제</strong>
                  </BButton>

                </BCol>
                <BCol class="text-center">
&lt;!&ndash;                  <BButton :disabled="!isCardSubmiting" type="button" variant="danger" @click="resetButton">
                    <strong>Reset</strong>
                  </BButton>&ndash;&gt;

&lt;!&ndash;                  <BButton v-show=" so.payStat==='99' " class="ml-2" type="button" variant="danger" @click="cancelButton" :disabled="isCardSubmiting">
                    <strong>주문취소</strong>
                  </BButton>&ndash;&gt;
                </BCol>
              </BRow>-->
            </div>

          </BCard>
        </BCol>
      </BRow>
    </BContainer>

    <b-modal id="termsModal" size="xl" title="이용약관">
      <BFormTextarea
          v-model="termsText"
          disabled
          max-rows="30"
          rows="30"
      ></BFormTextarea>
    </b-modal>

    <MultiOrderTax :order="so" />

    <Spinner :showSpinner="loading" showMessage="결제중" infoMessage=" "/>
  </div>
</template>

<script>
import {
  apiCall,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  commify, alertConfirm,
  warningAlertPageMove,
  warpSite,
} from '../../../common/utils';
import {
    PayStatOpts, 
} from '../../../common/salesType';
import terms from "../user-order/_terms";
import DaumPostcode from 'vuejs-daum-postcode';
import moment from "moment";
import html2pdf from 'html2pdf.js';
import MultiOrderTax from './MultiOrderTax';

const _order = {
  prd_name: '',
  sales_code : null,
  sales_id: null,
  disp_name: '',
  prd_code: null,
  dept_code: null,
  unit_price: 0,
  qty: 0,
  warranty_mon: null,
  price: 0,
  pay_amt: null,
  pay_type: null,
  cu_seq: null,
  cu_id: null,
  ord_dt: null,
  email: null,
  name: null,
  phone_no: null,
  comp_no: null,
  comp_name: null,
  pr_name: null,
  address: null,
  zip_code: null,
  appr_yn: null,
  appr_dt: null,
  send_stat: null,
  send_dt: null,
  pay_stat: null,
  pay_dt: null,
  tax_stat: null,
  tax_dt: null,
  tax_seq: null,
  biz_kind: null,
  cncl_yn: null,
  cncl_dt: null,
  cncl_desc: null,
  description: '',
  reg_dt: null,
  upd_dt: null,
  reg_id: null,
  upd_id: null,
  agreement: null,
  auth_no: null,
  addr_detail: '',
  auth_seq: null,
  trs_no: null,
  cncl_no: null,
};


export default {
  name: 'MultiOrderDetail',
  components: {
    MultiOrderTax
  },
  props: ['orderToken'],
  data() {
    return {
      isBoucherOrder: false,
      orderNumber: null,
      prdCode: '2106-0002',
      so: null,
      business: {},
      order: cloneVar(_order),
      isEmailChecked: false,
      termsText: terms[0].text,
      taxStatOpts: [
        {value: null, text: '개인/사업자 선택'},
        {value: '03', text: '개인'},
        {value: '01', text: '사업자'},
      ],
      isSubmiting: false,
      isCardSubmiting: false,
      isCanceling: false,
      isEmailSending: false,
      prdInfo: null,
      imgSrc: null,
      unitPrice: 0,
      totalPrice: 0,
      orderEnabled: false,
      prdCodeOpts: [
        {text: '2개(1+1)', value: '2106-0002'},
        {text: '1개', value: '2106-0001'},
      ],
      payTypeOpts: {
        '9999': '주문생성',
        '0000': '결제대기',
        '1000': '현금(계좌)',
        '0001': '쿠폰',
        '0010': '포인트',
        '0011': '포인트|쿠폰',
        '0100': '카드',
        '0101': '카드|쿠폰',
        '0110': '카드|포인트',
        '0111': '카드|포인트|쿠폰',
        '2000': '후불',
        '1001': '현금|쿠폰',
        '1010': '현금|포인트',
        '1011': '현금|포인트|쿠폰',
        '1100': '현금|카드',
        '1101': '현금|카드|쿠폰',
        '1110': '현금|카드|포인트',
        '1111': '현금|카드|포인트|쿠폰',
      },
      payMethods: {
        'CARD': '신용카드',
        'BANK': '계좌이체',
        'VBANK': '가상계좌',
        'CELLPHONE': '휴대폰',
        'CLGIFT': '문화상품권',
        'CASHRCPT': '현금영수증',
        'KAKAO': '카카오',
        'NAVER': '네이버'
      },
      payment: null,
      prdFields: [
        // {key:'prd_code', label: '제품코드' },
        {key:'disp_name', label: '라이센스명' },
        {key:'warranty_mon', label: '사용기간', formatter: v=>{ return `${v}개월` }},
        {key:'unit_price', label: '금액', formatter: v=>{  return commify(v)}},
      ],
      isModalTerms: false,
      discountPrice: 0,
      loading: false,
    }
  },
  async created() {
    try {
      // await this.getPrdInfo();
      await this.getSalesOrderInfo();
      await this.limitedValidation();

      if (!this.so.taxStat) {
        await this.$bvModal.show('bv-modal-order-tax');
      }

      if(this.so.xcostUser.business) {
        this.business = this.so.xcostUser.business;
      }
      // this.unitPrice = commify(this.so.amount) + ' 원';
      this.totalPrice = this.so.amount;

      // console.log("this.so ==================> ", this.so);
      // console.log("this.so.products ==================> ", this.so.products);

      await this.salesProductVerify();
      await this.productPriceSummary();

      // console.log('so ==============> ', this.so);
      if (this.so.type === 'MULTI') {
        this.so.products.forEach( item => {
          if (item.discount_yn === 'Y') this.discountPrice += item.discount_price;
        });
      } else {
        this.discountPrice = this.so.discountAmount;
      }
    }catch(err) {
      console.log(err);
    }
  },
  beforeMount() {

  },
  mounted() {
    console.log("-------- mounted: OrderForm ----- prdCode : " + this.prdCode);

  },
  methods: {
    commify(num) { return commify(num) },
    addressModalShow() {
      this.$bvModal.show("addressModal");
    },
    calcPrice() {
      this.totalPrice = commify(this.so.price) + ' 원';
    },
    gotoXcostMe() {
      window.location.href = warpSite();
    },

    resetButton() {
      this.isCardSubmiting = false;
    },

    saveAsPdf() {
      const element = this.$refs.pdfSection;
      const options = {
        margin:       0.1,               // 페이지 여백 설정
        filename:     `HowCost_주문내역_견적서_${this.orderNumber}.pdf`,   // 저장될 PDF 파일명
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },     // 해상도
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' } // PDF 크기 및 방향
      };
      html2pdf().set(options).from(element).save(); // PDF로 변환 및 저장
    },

    async cancelButton() {
      const ret = await alertConfirm(
          this.$bvModal,
          `주문이 취소되면 복구할 수 없습니다. 주문을 취소하시겠습니까?`,
          '주문취소'
      );
      if( !ret ) return;

      console.log("getOrderInfo ---> ", this.orderToken);
      try {
        const r = await apiCall('post', `/api/sales/cancel`, {orderToken: this.orderToken});
        if (r.code===200) {
          await alertWarn(this.$bvModal, '주문이 취소 되었습니다', '주문정보 취소 완료');
          window.location.href = warpSite();
        }else{
          await alertWarn(this.$bvModal, '주문정보 링크가 만료되었습니다', '주문정보 없음');
          return;
        }

      } catch (err) {
        console.log(err);
      }

    },

    async getSalesOrderInfo() {
      // console.log("getOrderInfo ---> ", this.orderToken);
      try {
        const r = await apiCall('post', `/api/sales/info`, {orderToken: this.orderToken});
        if (r.code===200) {
          // console.log("sales info ==========> ", r.result);

          if( r.result.cancelYn==='Y'){
            await alertWarn(this.$bvModal, '주문 정보가 없거나 취소된 주문입니다.', '주문정보 없음');
            window.location.href = warpSite();
            return;
          }

          this.so = r.result;
          this.so['agreement'] = 'N';
          this.orderNumber = this.so._id.toUpperCase();

          let title = r.result.salesMaster.title;
          // console.log( r.result );
          if( title.indexOf('바우처') > -1){
            console.log( '바우처 오더 입니다.')
            this.isBoucherOrder = true;
          }

          if (this.so.payStat === '00' && this.so.payment) {
            await this.getPaymentInfo(this.order.trsNo);
          }

        }else{
          await alertWarn(this.$bvModal, '주문정보 링크가 만료되었습니다', '주문정보 없음');
          return;
        }
      } catch (err) {
        console.log(err);
      }


    },

    async salesProductVerify() {
      if (this.so.payStat !== '99') {
        return ;
      }

      try {
        const r = await apiCall('POST', `/api/product/verify`, this.so);
        if (r.code === 200) {
          return r;
        } else {
          throw r;
        }
      } catch (error) {
        console.log(error);
        await warningAlertPageMove( this.$bvModal, error.message, '주문확인', '#/' );
      }
    },

    async selectPrdCode() {
      await this.getPrdInfo();

      try {
        if (this.prdCode === '2106-0002') {
          this.order.qty = 2;
        } else {
          this.order.qty = 1;
        }
        this.order.price = this.prdInfo.unit_price * this.order.qty;
        this.unitPrice = this.prdInfo.unit_price;
        this.totalPrice = commify(this.order.price) + ' 원';
      } catch (err) {
        console.log(err);
      }

    },


    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },

    async getPrdInfo() {
      console.log("getPrdInfo ---- " + this.prdCode);

      try {
        const r = await apiCall('get', `/api/order/prd-info/${this.prdCode}`);
        if (r.result) {
          this.prdInfo = r.result[0];
          this.imgSrc = $baseURL + "/images/" + this.prdInfo.file_nm;
          //console.log( this.prdInfo );
          //console.log( this.imgSrc );
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getPaymentInfo(trsNo) {
      console.log("getPaymentInfo ---- " + trsNo);

      try {
        const param = {orderToken: this.orderToken, trsNo: trsNo};
        const r = await apiCall('post', `/api/payment/info`, param);

        if (r.result) {
          this.payment = r.result[0];
          //console.log( this.prdInfo );
          //console.log( this.imgSrc );
        }
      } catch (err) {
        console.log(err);
      }
    },

    async payWithBoucher(){

      console.log("-------payWithBoucher---------");

      try {

        if (this.so.agreement !== "Y") {
          await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
          return;
        }

        const ret = await alertConfirm(
            this.$bvModal,
            `결제 안내 메일을 발송합니다. 바우처신청을 진행 하시겠습니까? `,
            '바우처 결제 확인'
        );
        if( !ret ) return;

        this.isSubmiting = true;

        const r = await apiCall('post', `/api/payment/sales/boucher`, {orderToken: this.orderToken});
        // const r = await apiCall('post', `/api/order/confirm`, this.order);
        //console.log( "onSubmit ----------------> result: ", r );
        if (r.code === 200) {
          await alertSuccess(this.$bvModal, '성공적으로 처리 되었습니다. 주문 내용 및 결제 방법은 이메일을 확인하시기 바랍니다.');
          window.location.href = warpSite();
          return;
        } else {
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        this.isSubmiting = false;

      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmiting = false;
      }

    },

    async payWithCash() {
      console.log("-------payWithCash---------");

      try {

        if (this.so.agreement !== "Y") {
          await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
          return;
        }

        const ret = await alertConfirm(
            this.$bvModal,
            `결제 안내 메일을 발송합니다. 현금결제/계좌이체를 진행 하시겠습니까? `,
            '현금/계좌이체 결제'
        );
        if( !ret ) return;

        this.isSubmiting = true;

        const r = await apiCall('post', `/api/payment/sales/cash`, {orderToken: this.orderToken});
        // const r = await apiCall('post', `/api/order/confirm`, this.order);
        //console.log( "onSubmit ----------------> result: ", r );
        if (r.code === 200) {
          await alertSuccess(this.$bvModal, '성공적으로 처리 되었습니다. 주문 내용 및 결제 방법은 이메일을 확인하시기 바랍니다.');
          window.location.href = warpSite();
          return;
        } else {
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        this.isSubmiting = false;

      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmiting = false;
      }

    },


    async payWithCreditCard() {
      console.log("-------- payWithCreditCard -------- ");

      try {
        if (this.so.agreement !== "Y") {
          await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
          return;
        }

        this.loading = true;
        this.isCardSubmiting = true;
        const r = await apiCall('post', `/api/payment/sales/gateway`, {orderToken: this.orderToken});
        if (r.code === 200) {
          const rr = r.result;
          await this.submitPayWindow(rr.pgData, rr.ret);

        } else {
          alert(r.message);
        }
      } catch (err) {
        alert(err.message);
        console.log("error on payWithCreditCard", err);
      }

    },

    async submitPayWindow(pgData, obj) {
      const frm = document.createElement('form');
      frm.setAttribute('charset', "utf-8");
      frm.setAttribute('accept-charset', "utf-8");
      frm.setAttribute('name', 'tranMgr');
      frm.setAttribute('action', obj.ActionURL);
      frm.setAttribute('target', 'payWindow');
      Object.keys(pgData).forEach(k => {
        const hField = document.createElement("input");
        hField.setAttribute('type', 'hidden');
        hField.setAttribute('name', k);
        hField.setAttribute('value', pgData[k]);
        frm.appendChild(hField);
      });
      // console.log('pgData --->', pgData);

      document.body.appendChild(frm);
      const popupX = (window.screen.width / 2) - (545 / 2);
      const popupY = (window.screen.height / 2) - (573 / 2);
      const winopts = "width=545,height=573,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no,resizable=no,left=" + popupX + ", top=" + popupY + ", screenX=" + popupX + ", screenY= " + popupY;
      const win = window.open("", "payWindow", winopts);

      try {
        if (win == null || win.closed || typeof win.closed == 'undefined' || win.screenLeft == 0) {
          alert('브라우저 팝업이 차단으로 설정되었습니다.\n 팝업 차단 해제를 설정해 주시기 바랍니다.');
          return false;
        }
      } catch (e){
        console.log(e);
        return false;
      }

      frm.submit();
    },

    resetForm() {
      this.order = cloneVar(_order);
      this.isEmailChecked = false;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    async productPriceSummary() {
      let basePrice = 0;
      this.so.products.forEach(item => {
        basePrice += item.unit_price;
      });
      this.unitPrice = basePrice;
    },

    async limitedValidation() {
      try {
        const salesMaster = this.so.salesMaster;
        const salesOrder = this.so;
        console.log("salesMaster ==========> ", salesMaster);

        //판매 종료된 상품
        if (salesMaster.useYn === 'N' && salesOrder.payStat === '99') {
          // 주문내역 삭제
          await apiCall('post', `/api/sales/cancel`, {orderToken: this.orderToken});
          throw new Error(`판매가 종료된 상품입니다.`);
        }

        //계정당 구매 횟수 제한
        if (salesMaster.accountLimited > 0 && salesOrder.payStat === '99') {
          const r = await apiCall('POST', `/api/sales/account-limited`, {xcostId: this.so.xcostUser._id, salesId: this.so.salesMaster._id});
          // console.log("salesMaster.accountLimited > 0", r);
          if (r.result >= salesMaster.accountLimited) {
            // 주문내역 삭제
            await apiCall('post', `/api/sales/cancel`, {orderToken: this.orderToken});
            throw new Error(`${salesMaster.accountLimited}회 까지만 구매가 가능한 상품입니다.`);
          }
        }

        //상품당 구매 횟수 제한
        if (salesMaster.qtyLimited > 0 && salesOrder.payStat === '99') {
          const r = await apiCall('POST', `/api/sales/qty-limited`, {salesId: this.so.salesMaster._id});
          // console.log("salesMaster.qtyLimited > 0", r);
          if (r.result >= salesMaster.qtyLimited) {
            // 주문내역 삭제
            await apiCall('post', `/api/sales/cancel`, {orderToken: this.orderToken});
            throw new Error(`준비한 상품 개수가 모두 소진되어 구매가 불가합니다.`);
          }
        }

        //패키지 상품가격 일치 확인
        // if (salesMaster.amount === (salesMaster.price - salesMaster.discountAmount) && salesOrder.payStat === '99') {
        //   // 주문내역 삭제
        //   await apiCall('post', `/api/sales/cancel`, {orderToken: this.orderToken});
        //   throw new Error(`설정된 상품 가격과 다르므로 해당 주문을 이용할 수 없습니다.`);
        // }
      } catch (e) {
        console.error(e);
        await warningAlertPageMove( this.$bvModal, e.message, '주문확인', '#/' );
      }
    },

  },

}
</script>
